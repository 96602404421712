<!-- HomeHeroSlider.vue -->
<template>
  <ConfigurableHeroSlider
    :images="props.sliderItems"
    :ariaRoledescription="t('home.heroSlider.aria.carousel')"
    :ariaLabel="t('home.heroSlider.aria.imageCarousel')"
    :progressBarAriaLabel="t('home.heroSlider.aria.progressBar')"
    :controlsAriaLabel="t('home.heroSlider.aria.carouselControls')"
    :prevAriaLabel="t('home.heroSlider.aria.previousSlide')"
    :nextAriaLabel="t('home.heroSlider.aria.nextSlide')"
    :toggleAriaLabel="t('home.heroSlider.aria.toggleCarousel')"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <template #subtitle>
      <slot name="subtitle"></slot>
    </template>
  </ConfigurableHeroSlider>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import ConfigurableHeroSlider from "./ConfigurableHeroSlider.vue";
import { SliderImage } from "../../composables/slider/types";
const { t } = useI18n();

const props = defineProps<{
  sliderItems: SliderImage[]; // Slider images
}>();
</script>
